import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorPawel as author } from 'data/authors';
import { Post } from 'components/blog/content/styled';
import img from 'img/blog/play-next.png';

const Content = () => {
  return (
    <Post>
      <p>
        Watching session replays became smoother. We’ve just launched <strong>autoplay</strong> in
        LiveSession!
      </p>
      <p>
        When one recording ends, you’ll see a progress bar. You can choose to replay the same
        session once again or cancel the autoplay. If you do nothing, you’ll be{' '}
        <strong>automatically redirected to the next session</strong>.
      </p>
      <p>
        Go to your{' '}
        <a href="https://app.livesession.io/app/sessions" target="_blank" rel="noopener noreferrer">
          session recording list
        </a>{' '}
        and try it yourself!
      </p>
      <p>
        As usual, if you have any questions or ideas for improvement, feel free to get in touch via
        live chat or send us an email to{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Autoplay',
  url: '/blog/autoplay/',
  description: 'Read about the autoplay feature in LiveSession.',
  author,
  img,
  imgSocial: img,
  date: '2020-06-23',
  category: '',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
